export const projectAddressList = [
  { value: "Adajan", label: "Adajan" },
  { value: "Bhatha", label: "Bhatha" },
  { value: "Bhatar", label: "Bhatar" },
  { value: "Jahangirabad", label: "Jahangirabad" },
  { value: "Jahangirpura", label: "Jahangirpura" },
  { value: "Kadodara Road", label: "Kadodara Road" },
  { value: "Kumbharia", label: "Kumbharia" },
  { value: "Pal", label: "Pal" },
  { value: "Rander", label: "Rander" },
  { value: "Vesu", label: "Vesu" },
  { value: "VIP Road", label: "VIP Road" },
];

export const getInitialProjectState = (
  projectDetails,
  InitialLogoImagePreview,
  InitialBannerImagePreview,
  InitialHorizontalFeaturedImagePreview,
  InitialVerticalFeaturedImagePreview,
  InitialBrochurePdfPreview
) => {
  return {
    possession_status: projectDetails?.possession_status
      ? projectDetails?.possession_status
      : "ready_possession",

    project_type: projectDetails?.project_type
      ? projectDetails?.project_type
      : "commercial",

    name: projectDetails?.name ? projectDetails?.name : "",

    one_line_specification: projectDetails?.one_line_specification
      ? projectDetails?.one_line_specification
      : "",

    location: projectDetails?.location
      ? { value: projectDetails.location, label: projectDetails.location }
      : null,

    // description_title: projectDetails?.description_title
    //   ? projectDetails?.description_title
    //   : "",

    description: projectDetails?.description ? projectDetails?.description : "",

    banner_image: projectDetails?.banner_image
      ? JSON.parse(projectDetails.banner_image)
      : [],

    banner_image_preview: projectDetails?.banner_image
      ? InitialBannerImagePreview
      : [],

    banner_images_error: "",

    // logo: projectDetails?.logo ? projectDetails.logo : "",

    // logo_preview: projectDetails?.logo ? InitialLogoImagePreview : "",

    horizontal_featured_image: projectDetails?.featured_image
      ? projectDetails?.featured_image
      : "",

    horizontal_featured_image_preview: projectDetails?.featured_image
      ? InitialHorizontalFeaturedImagePreview
      : "",

    vertical_featured_image: projectDetails?.vertical_featured_image
      ? projectDetails?.vertical_featured_image
      : "",

    vertical_featured_image_preview: projectDetails?.vertical_featured_image
      ? InitialVerticalFeaturedImagePreview
      : "",

    rera_number: projectDetails?.rera_number ? projectDetails?.rera_number : "",

    project_contact_number: projectDetails?.project_contact_number
      ? projectDetails?.project_contact_number
      : "",

    google_place_id: projectDetails?.google_place_id
      ? projectDetails?.google_place_id
      : "",
    // rate_us_on_google: projectDetails?.rate_us_on_google
    //   ? projectDetails?.rate_us_on_google
    //   : "",

    // google_reviews: projectDetails?.google_reviews
    //   ? projectDetails?.google_reviews
    //   : "",

    brochure: projectDetails?.brochure ? projectDetails?.brochure : "",

    brochure_preview: projectDetails?.brochure ? InitialBrochurePdfPreview : [],
  };
};

export const projectValidate = (
  temp,
  fieldValues,
  values,
  setValues,
  setErrors
) => {
  var expression =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi;
  var regex = new RegExp(expression);
  if ("name" in fieldValues) {
    temp.name = fieldValues.name ? "" : "Name is required !";
  }
  if ("one_line_specification" in fieldValues) {
    temp.one_line_specification = fieldValues.one_line_specification
      ? ""
      : "One line specification is required !";
  }
  if ("location" in fieldValues) {
    temp.location = fieldValues.location ? "" : "Location is required !";
  }
  // if ("description_title" in fieldValues) {
  //   temp.description_title = fieldValues.description_title
  //     ? ""
  //     : "Description title is required !";
  // }
  if ("description" in fieldValues) {
    temp.description = fieldValues.description
      ? ""
      : "Description is required !";
  }
  if ("rera_number" in fieldValues) {
    temp.rera_number = fieldValues.rera_number
      ? ""
      : "Rera Number is required !";
  }
  if ("project_contact_number" in fieldValues) {
    if (!fieldValues.project_contact_number) {
      temp.project_contact_number = "Project contact number is required !";
    } else {
      temp.project_contact_number =
        fieldValues.project_contact_number.length === 10
          ? ""
          : "Project contact number is not valid !";
    }
  }
  // if ("logo" in fieldValues) {
  //   temp.logo = "";
  //   if (!fieldValues.logo) {
  //     temp.logo = "logo required !";
  //   }
  //   if (fieldValues.logo.size > 50000000) {
  //     temp.logo = "Image size should not be greater than 50MB";
  //     setValues({
  //       ...values,
  //       logo_preview: "",
  //       logo: "",
  //     });
  //   }
  //   if (
  //     fieldValues?.logo?.name &&
  //     !fieldValues?.logo?.name?.match(/.(jpg|jpeg|png|gif)$/i)
  //   ) {
  //     temp.logo = "Invalid logo image";
  //     setValues({
  //       ...values,
  //       logo_preview: "",
  //       logo: "",
  //     });
  //   }
  // }
  if ("horizontal_featured_image" in fieldValues) {
    temp.horizontal_featured_image = "";
    if (!fieldValues.horizontal_featured_image) {
      temp.horizontal_featured_image = "featured image required !";
    }
    if (fieldValues.horizontal_featured_image.size > 50000000) {
      temp.horizontal_featured_image =
        "Image size should not be greater than 50MB";
      setValues({
        ...values,
        horizontal_featured_image_preview: "",
        horizontal_featured_image: "",
      });
    }
    if (
      fieldValues?.horizontal_featured_image?.name &&
      !fieldValues?.horizontal_featured_image?.name?.match(/.(jpg|jpeg|png)$/i)
    ) {
      temp.horizontal_featured_image = "Invalid featured image";
      setValues({
        ...values,
        horizontal_featured_image_preview: "",
        horizontal_featured_image: "",
      });
    }
    if ("google_place_id" in fieldValues) {
      temp.google_place_id = fieldValues.google_place_id
        ? ""
        : "Google Place Id is required !";
    }
  }
  if ("vertical_featured_image" in fieldValues) {
    temp.vertical_featured_image = "";
    if (!fieldValues.vertical_featured_image) {
      temp.vertical_featured_image = "featured image required !";
    }
    if (fieldValues.vertical_featured_image.size > 50000000) {
      temp.vertical_featured_image =
        "Image size should not be greater than 50MB";
      setValues({
        ...values,
        vertical_featured_image_preview: "",
        vertical_featured_image: "",
      });
    }
    if (
      fieldValues?.vertical_featured_image?.name &&
      !fieldValues?.vertical_featured_image?.name?.match(/.(jpg|jpeg|png)$/i)
    ) {
      temp.vertical_featured_image = "Invalid featured image";
      setValues({
        ...values,
        vertical_featured_image_preview: "",
        vertical_featured_image: "",
      });
    }
    if ("google_place_id" in fieldValues) {
      temp.google_place_id = fieldValues.google_place_id
        ? ""
        : "Google Place Id is required !";
    }
  }
  if ("brochure" in fieldValues) {
    temp.brochure = "";
    if (
      fieldValues?.brochure?.name &&
      !fieldValues?.brochure?.name?.match(/.(pdf)$/i)
    ) {
      temp.brochure = "Invalid Documents";
      setValues({
        ...values,
        brochure_preview: "",
        brochure: "",
      });
    }
  }
  // if ("rate_us_on_google" in fieldValues) {
  //   temp.rate_us_on_google = "";
  //   if (
  //     fieldValues?.rate_us_on_google?.length > 0 &&
  //     !fieldValues?.rate_us_on_google?.match(regex)
  //   ) {
  //     temp.rate_us_on_google = "Enter valid url !";
  //   }
  // }
  // if ("google_reviews" in fieldValues) {
  //   temp.google_reviews = "";
  //   if (
  //     fieldValues?.google_reviews?.length > 0 &&
  //     !fieldValues?.google_reviews?.match(regex)
  //   ) {
  //     temp.google_reviews = "Enter valid url !";
  //   }
  // }
  setErrors({
    ...temp,
  });
  if (fieldValues === values) return Object.values(temp).every((x) => x === "");
};
